import React, { useState, useContext, useEffect } from 'react'
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CCol
} from '@coreui/react'
import { checkPropTypes } from 'prop-types'
import CIcon from '@coreui/icons-react'
import { ModalAlertContext } from 'contexts/ModalAlert'
import { useSpinnerReact } from 'contexts/SpinnerReact'

import './index.scss'

const ModalsAlert = () => {
    const { modal } = useContext(ModalAlertContext);
    const { setSpinnerReact } = useSpinnerReact();

    const [opened, setOpened] = useState(false)

    const {
        type,
        message
    } = modal

    useEffect(() => {
        if (message) {
            setOpened(!opened)
            setSpinnerReact(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modal])

    return (
        <>
            <CModal 
                show={opened} 
                onClose={() => setOpened(!opened)}
                color={type}
            >
                <CModalBody>
                    <CRow>
                        <CCol xs="12" className="text-center">
                            <CIcon
                                className={`c-sidebar-brand-full mb-3 text-${type === 'danger' ? type : 'success'}`}
                                name={type === 'danger' ? 'cil-x-circle' : 'cil-check-circle'}
                                height={100}
                            />
                        </CCol>

                        <CCol xs="12" className="text-center text-modal">
                            {message}
                        </CCol>
                    </CRow>
                </CModalBody>

                <CButton 
                    color={type === 'danger' ? type : 'success'} 
                    onClick={() => setOpened(!opened)} 
                    className="mb-3 ml-4 mr-4 pt-3 pb-3"
                >
                    OK
                </CButton>
            </CModal>
        </>
    )
}

export default ModalsAlert

export const Modals = (props) => {
    const {
        children,
        handleClick,
        type,
        title,
        size,
        colorBtn,
        textBtn,
        textBtnAct,
        textBtnCancel,
        iconBtn,
        iconClassName,
        iconSizeBtn
    } = props

    const [opened, setOpened] = useState(false)

    function execute() {
        setOpened(!opened)
        handleClick()
    }

    return (
        <>
            {!iconBtn || !iconClassName ? (
                <CButton color={colorBtn ? colorBtn : 'success'} onClick={() => setOpened(!opened)}>
                    {textBtn}
                </CButton>
            ) : (
                <CButton size={iconSizeBtn} className={iconClassName} onClick={() => setOpened(!opened)}>
                    <CIcon name={iconBtn} />
                </CButton>
            )}
         
            <CModal 
                show={opened} 
                onClose={() => setOpened(!opened)}
                color={type}
                size={size}
            >
                <CModalHeader closeButton>
                    <CModalTitle>{title}</CModalTitle>
                </CModalHeader>

                <CModalBody>
                    {children}
                </CModalBody>

                <CModalFooter>
                    {handleClick && <CButton color={type ? type : colorBtn} onClick={execute}>{textBtnAct}</CButton> }
                    <CButton color="secondary" onClick={() => setOpened(!opened)}>{textBtnCancel ? textBtnCancel :'Cancelar'}</CButton>
                </CModalFooter>
            </CModal>
        </>
    )
}

// Verifica os tipos passados nas props - pesquisa reusable react documentação
// function->fun, bool->boll, array->array, number->number, object->object, string->string, symbol->symbol, 
// qualquer-coisa->node, elemento-react->element, verifica-enum->oneOf
Modals.propType = {
    handleClick: checkPropTypes.func,
    type: checkPropTypes.string
}

//export default Modals
