import React, { useState, createContext, useContext } from "react"

export const FormsContext = createContext()

export default function FormsProvider(props) {
  const [forms, setForms] = useState({})

  const {
    children,
    showForm,
    setShowForm,
    isUpdate, 
    setIsUpdate
  } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [totalResults, setTotalResults] = useState(0)
  const [totalResultsOnPage, setTotalResultsOnPage] = useState(0)
  const [dataList, setDataList] = useState([])
  const [fillOutForm, setFillOutForm] = useState({})

  return (
    <FormsContext.Provider value={{ 
      forms, 
      setForms,
      currentPage, 
      setCurrentPage, 
      totalPages, 
      setTotalPages, 
      totalResults, 
      setTotalResults,
      totalResultsOnPage, 
      setTotalResultsOnPage,
      isUpdate, 
      setIsUpdate,
      dataList, 
      setDataList,
      showForm,
      setShowForm,
      fillOutForm, 
      setFillOutForm
    }}>
      { children }
    </FormsContext.Provider>
  )
}

export function useForms() {
  const { 
    forms, 
    setForms, 
    currentPage, 
    setCurrentPage, 
    totalPages, 
    setTotalPages, 
    totalResults, 
    setTotalResults,
    totalResultsOnPage, 
    setTotalResultsOnPage,
    isUpdate, 
    setIsUpdate,
    dataList, 
    setDataList,
    showForm,
    setShowForm,
    fillOutForm, 
    setFillOutForm
  } = useContext(FormsContext)

  return { 
    forms, 
    setForms, 
    currentPage, 
    setCurrentPage, 
    totalPages, 
    setTotalPages, 
    totalResults, 
    setTotalResults,
    totalResultsOnPage, 
    setTotalResultsOnPage,
    isUpdate, 
    setIsUpdate,
    dataList, 
    setDataList,
    showForm,
    setShowForm,
    fillOutForm, 
    setFillOutForm
   }
}