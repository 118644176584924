import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'workers/polyfill'
import * as serviceWorker from 'workers/serviceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import store from 'store/store'

import ToastProvider from 'contexts/Toast'
import Authenticaded from 'contexts/Authenticaded'
import Toast from 'components/Toast'
import ModalAlertProvider from 'contexts/ModalAlert'
import ModalsAlert from 'components/Modals'
import SpinnerReactProvider from 'contexts/SpinnerReact'
import SpinnerReact from 'components/SpinnerReact'
import FormsProvider from 'contexts/Forms'

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    <FormsProvider >
      <ToastProvider>
        <Toast />
          <SpinnerReactProvider>
            <SpinnerReact />
            <ModalAlertProvider>
              <ModalsAlert />
              <Authenticaded>
                  <App />
              </Authenticaded>
            </ModalAlertProvider>
          </SpinnerReactProvider>
      </ToastProvider>
    </FormsProvider>
  </Provider>, 
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
